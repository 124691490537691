<template>
  <div>
    <div class="solution-page">
      <img class="tp-img" src="../../assets/images/solution/图片20.png" />
      <div class="solution-content">
        <div class="solution-main">
          <h2>解决方案</h2>
          <em></em>
          <div class="left-nav">
            <ul>
              <li>
                <div @click="getRouter1()">综合处理方案</div>
              </li>
              <li>
                <div @click="getRouter2()">模块化处理方案</div>
              </li>
              <li>
                <div @click="getRouter5()">智慧收运信息监管平台-eDIS</div>
              </li>
              <li>
                <div @click="getRouter3()">3D数字孪生系统-eTD</div>
              </li>
              <li>
                <div @click="getRouter4()">生态农业处理方案</div>
              </li>
              <li>
                <div @click="goback()" v-if="visiable0">返回主页</div>
              </li>
            </ul>
          </div>
          <div class="font"></div>
          <div class="content-paging11" v-if="visiables">
            <!-- 综合处理方案 -->
            <div class="section5" v-if="visiable1">
              <div class="synopsis">
                <h2>综合处理方案</h2>
              </div>
              <div class="synopsis-text" style="text-align:center">
                <p style="text-align:center">
                  适用于日处理量在50t~500t的餐厨、厨余、果蔬等有机垃圾的处理；所建设的项目设备科学先进，工艺路线完整，配套设施齐全，资源利用程度高。
                </p>
                <div class="img-title10">
                  <img
                    src="../../assets/images/home/zh1.webp"
                    style="margin-top: 10px; height: 300px"
                  />
                  <img
                    src="../../assets/images/home/zh.webp"
                    style="margin-top: 10px; height: 300px"
                  />
                </div>
              </div>
            </div>

            <!-- 生态农业模块 -->
            <div class="section2" v-if="visiable4">
              <div class="synopsis">
                <h2>生态农业模块</h2>
              </div>
              <div class="synopsis-text">
                <p>
                  该示范基地以“科技生态循环”为理念，坚持可持续农业生产、资源利用和环境保护的目标，打造多个主题生态农业科技馆，全方面的综合利用与宣教相结合，打造新型现代农业生态循环示范产业园。
                </p>

                <div class="img-title10">
                  <img
                    src="../../assets/images/project/xishan-3.webp"
                    style="margin-top: 20px; height: 300px"
                  />
                  <img
                    src="../../assets/images/project/xishan-4.webp"
                    style="margin-top: 20px; height: 300px"
                  />
                </div>
              </div>
            </div>

            <!-- 3D数字孪生 -->
            <div class="section5" v-if="visiable3">
              <div class="synopsis">
                <h2>3D数字孪生系统-eTD</h2>
              </div>
              <div class="synopsis-text">
                <p>
                  针对有机垃圾全程监管的特点，我司独立自主研发了3D数字孪生技术、智慧收运数字化平台。实现了数据的高效、安全、稳定的计算和存储服务，减少管理和运营的风险。该项技术已率先成功运用于餐厨项目，处于行业领先。
                </p>
                <div class="img-title10">
                  <img
                    src="../../assets/images/solution/3D1.png"
                    style="width: 600px"
                  />
                  <img
                    src="../../assets/images/solution/3D2.jpg"
                    style="width: 600px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content-paging1" v-if="visiabless">
            <!-- 模块化处理终端 -->
            <div class="section1" v-if="visiable2">
              <div class="synopsis">
                <h2>模块化处理终端</h2>
              </div>
              <div class="synopsis-text">
                <p>
                  处理量在 5t-100t
                  的餐厨、厨余、果蔬等有机垃圾的处理，可根据处理量、使用场所较小等条件，选择不同的处理模块和组合，对场地要求不高，占地面积小，可室内和室外使用；运输、安装方便，操作简便，自动化程度高，可远程控制。分布式方案系统由投料模块、干湿分离模块、热解模块、分离模块、水处理模块、堆肥模块及配套储罐组成，各模块组成可根据客户需求灵活选配。
                </p>
                <div class="img-title11">
                  <img
                    src="../../assets/images/solution/519.png"
                    style="width: 400px; height: 300px"
                  />
                  <img
                    src="../../assets/images/solution/519-1.png"
                    style="width: 400px; height: 300px"
                  />
                </div>
                <img
                  src="../../assets/images/solution/519-2.png"
                  style="width: 800px; height: 380px"
                />
              </div>
            </div>

            <!-- 智慧收运信息监管平台-eDIS -->
            <div class="section5" v-if="visiable5">
              <div class="synopsis">
                <h2>智慧收运信息监管平台-eDIS</h2>
              </div>
              <div class="synopsis-text11">
                <p>
                  针对有机废弃物处理，专业研发完整的信息流平台，将废弃物点源收集、运输到后端处理处置的全流程进行监管。
                </p>
                <img
                  src="../../assets/images/solution/tupian0.png"
                  style="width: 1000px"
                />
                <div class="img-title10">
                  <img
                    src="../../assets/images/solution/图片30.png"
                    style="width: 450px; height: 280px; margin-left: 100px"
                  />
                  <img
                    src="../../assets/images/solution/图片31.png"
                    style="width: 450px; height: 280px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="content-paging" v-if="visiable">
            <div
              class="solution-imgtext3"
              v-for="(url, index) in Itemsrc"
              :key="index"
            >
              <div @click="goTo(url.id)">
                <img
                  style="width:370px;height:270px;display: inline-block;"
                  :src="url.imgUrl"
                />
                <div class="img-title1">
                  <el-divider direction="vertical"></el-divider>
                  <span>{{ url.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      visiable: true,
      visiable2: false,
      visiables: false,
      visiabless: false,
      visiable3: false,
      visiable4: false,
      visiable5: false,
      visiable1: false,
      visiable0: false,
      Itemsrc: [
        {
          id: 1,
          imgUrl: require("../../assets/images/home/zh.webp"),
          name: "综合处理方案",
          routerName: "synthesize",
        },
        {
          id: 3,
          imgUrl: require("../../assets/images/solution/3D1.png"),
          name: "3D数字孪生系统-eTD",
          routerName: "smartHarvest",
        },
        {
          id: 4,
          imgUrl: require("../../assets/images/solution/图片5.png"),
          name: "生态农业处理方案",
          routerName: "ecology",
        },
        {
          id: 2,
          imgUrl: require("../../assets/images/solution/图片2.png"),
          name: "模块化处理终端",
          routerName: "modular",
        },
        {
          id: 5,
          imgUrl: require("../../assets/images/solution/图片31.png"),
          name: "智慧收运信息监管平台-eDIS",
          routerName: "shouYun",
        },
      ],
    };
  },
  // async mounted() {
  //   window.addEventListener("scroll", (e) => {
  //     // 这里做一个 节流 操作
  //     if (this.timer) return;
  //     this.timer = setTimeout(() => {
  //       this.query("img[data-src]").forEach((img) => {
  //         const rect = img.getBoundingClientRect();
  //         if (rect.top >= window.innerHeight) {
  //           img.src = img.dataset.src;
  //           // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
  //           img.removeAttribute("data-src");
  //         }
  //       });
  //       clearTimeout(this.timer);
  //       // 这里一定要把定时器置为 null
  //       this.timer = null;
  //     }, 300);
  //   });
  // },
  methods: {
    // query(selector) {
    //   return Array.from(document.querySelectorAll(selector));
    // },

    goback() {
      this.visiable = true;
      this.visiables = false;
      this.visiabless = false;
      this.visiable0 = false;
      this.visiable3 = false;
      this.visiable4 = false;
      this.visiable5 = false;
      this.visiable1 = false;
      this.visiable2 = false;
    },
    goTo(e) {
      console.log(e);
      this.visiable = false;
      this.visiable0 = true;
      if (e == 1) {
        this.getRouter1();
      } else if (e == "2") {
        this.getRouter2();
      } else if (e == "3") {
        this.getRouter3();
      } else if (e == "4") {
        this.getRouter4();
      } else if (e == "5") {
        this.getRouter5();
      }
    },
    getRouter2() {
      this.visiable2 = true;
      this.visiable0 = true;
      this.visiables = false;
      this.visiabless = true;
      this.visiable3 = false;
      this.visiable4 = false;
      this.visiable5 = false;
      this.visiable1 = false;
      this.visiable = false;
    },
    getRouter1() {
      this.visiables = true;
      this.visiabless = false;
      this.visiable1 = true;
      this.visiable0 = true;
      this.visiable3 = false;
      this.visiable4 = false;
      this.visiable5 = false;
      this.visiable2 = false;
      this.visiable = false;
    },
    getRouter3() {
      this.visiabless = false;
      this.visiables = true;
      this.visiable3 = true;
      this.visiable0 = true;
      this.visiable2 = false;
      this.visiable4 = false;
      this.visiable5 = false;
      this.visiable1 = false;
      this.visiable = false;
    },
    getRouter4() {
      this.visiabless = false;
      this.visiables = true;
      this.visiable4 = true;
      this.visiable0 = true;
      this.visiable3 = false;
      this.visiable2 = false;
      this.visiable5 = false;
      this.visiable1 = false;
      this.visiable = false;
    },
    getRouter5() {
      this.visiabless = true;
      this.visiables = false;
      this.visiable2 = false;
      this.visiable3 = false;
      this.visiable4 = false;
      this.visiable0 = true;
      this.visiable5 = true;
      this.visiable1 = false;
      this.visiable = false;
    },
  },
};
</script>
<style scoped>
.content-paging11 .synopsis-text p {
  line-height: 35px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}
.content-paging11 {
  height: 500px;
  width: 100%;
  margin: auto;
}
.content-paging11 .synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 15px 0;
}
.content-paging1 .synopsis-text p {
  line-height: 35px;
  font-size: 15px;
  text-indent: 2em;
  text-align: left;
}
.content-paging1 {
  height: 1000px;
  width: 100%;
  margin: auto;
}
.content-paging1 .synopsis h2 {
  font-size: 31px;
  color: #3b3b3b;
  padding: 15px 0;
}
.content-paging img {
  transition: 2s;
  overflow: hidden;
}
.content-paging .el-image:hover {
  transform: scale(1.1);
}
/deep/.el-divider--vertical {
  width: 6px;
  height: 1.6em;
  margin: 0px 8px;
  vertical-align: middle;
  position: relative;
}

.img-title1 span {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.img-title1 {
  position: absolute;
  bottom: 60px;
  left: 40px;
}
.tp-img {
  height: 380px;
  width: 100%;
}

.solution-imgtext3 {
  height: 300px;
  width: 31%;
  margin: 1%;
  overflow: hidden;
  position: relative;
}



.img-left {
  display: flex;
  flex-direction: column;
  width: 61%;
  margin: 1%;
}



.img-right {
  width: 35%;
  display: flex;
  /* margin: 1%; */
  flex-direction: column;
}
.content-paging {
  width: 1250px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin: 1%; */
}

.ac span {
  display: none;
  text-decoration: none;
}
.examine {
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #8cc6ee;
  color: #fff;
  font-size: 14px;
  border-radius: 20px;
  position: absolute;
  margin-top: 150px;
  margin-left: 50px;
}
.solution-page img {
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
}
.fonts {
  padding-top: 60px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.case-box {
  width: 100%;
  padding-top: 20px;
  overflow: hidden;
}
.case-box ul li {
  width: 23%;
  margin: 10px 1%;
  float: left;
  overflow: hidden;
}
.img-box img {
  width: 100%;
  background: #76c817;
}
.img-title11 {
  width: 800px;
  height: 350px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content-paging1 .img-title11 img {
  width: 100%;
  margin: auto;
  height: 420px;
}
.img-title10 {
  width: 1070px;
  height: 300px;
  margin-left: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content-paging1 .img-title10 img {
  width: 48%;
  margin-left: 1%;
  height: 420px;
}
.content-paging11 .img-title10 img {
  width: 48%;
  margin-left: 1%;
  height: 300px;
}

.solution-main {
  text-align: center;
  width: 100%;
  margin: auto;
  float: left;
}
.solution-main h2 {
  font-size: 35px;
}
.solution-main em {
  display: block;
  width: 60px;
  height: 5px;
  margin: auto;
  margin-top: 10px;
  background: #8cc6ee;
}
.solution-main h2 {
  color: #3b3b3b;
  margin-top: 65px;
}
.left-nav {
  padding: 20px 0;
  height: 40px;
  line-height: 40px;
  margin-left: 00px;
  margin-top: 15px;
}
.font {
  width: 1350px;
  padding: 0px 0 30px 40px;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
}
.left-nav ul li {
  display: block;
  float: left;
  font-size: 20px;
  margin-left: 20px;
  text-align: center;
}
.left-nav ul li div {
  display: block;
  padding: 0 20px;
  background: #8cc6ee;
  border: 1px solid white;
  color: #fff;
}
.solution-content {
  width: 1350px;
  margin: auto;
}
.solution-page {
  overflow: hidden;
  min-width: 1250px;
  height: 100%;
  margin: auto;
  padding: 0;
  background: #fff;
}
</style>
